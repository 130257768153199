<template>
    <div>
        <div class="nk-chat">
            <div class="nk-chat-aside">
                <div class="nk-chat-aside-head">
                    <div class="nk-chat-aside-user">
                        <div class="dropdown">
                            <div class="title">Chats</div>
                        </div>
                    </div><!-- .nk-chat-aside-user -->
                    <ul class="nk-chat-aside-tools g-2">
                        <li>
                            <div class="dropdown">
                                <a href="#" class="btn btn-round btn-icon btn-light dropdown-toggle" data-toggle="dropdown">
                                    <em class="icon ni ni-setting-alt-fill"></em>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <ul class="link-list-opt no-bdr">
                                        <li><a href="#"><span>Settings</span></a></li>
                                        <li class="divider"></li>
                                        <li><a href="#"><span>Message Requests</span></a></li>
                                        <li><a href="#"><span>Archives Chats</span></a></li>
                                        <li><a href="#"><span>Unread Chats</span></a></li>
                                        <li><a href="#"><span>Group Chats</span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul><!-- .nk-chat-aside-tools -->
                </div><!-- .nk-chat-aside-head -->
                <div class="nk-chat-aside-body" data-simplebar="init"><div class="simplebar-wrapper" style="margin: 0px;"><div class="simplebar-height-auto-observer-wrapper"><div class="simplebar-height-auto-observer"></div></div><div class="simplebar-mask"><div class="simplebar-offset" style="right: 0px; bottom: 0px;"><div class="simplebar-content-wrapper" style="height: 100%; overflow: hidden scroll;"><div class="simplebar-content" style="padding: 0px;">
                    <div class="nk-chat-aside-search">
                        <div class="form-group">
                            <div class="form-control-wrap">
                                <div class="form-icon form-icon-left">
                                    <em class="icon ni ni-search"></em>
                                </div>
                                <a-select placeholder="Search for user" show-search style="width:100%" option-filter-prop="children" :filter-option="filterOption">
                                    <a-select-option v-for="u in users" :key="u.id" v-on:click="getBlankUserMessages(u)">
                                        {{ u.firstName + ' ' + u.lastName }}
                                    </a-select-option>
                                </a-select>
                            </div>
                        </div>
                    </div><!-- .nk-chat-aside-search -->
                    <div class="nk-chat-list">
                        <h6 class="title overline-title-alt">Users</h6>
                        <ul class="chat-list">
                            <li class="chat-item" v-for="c in conversations" v-bind:key="c.userId">
                                <a class="chat-link chat-open" v-on:click="getUserMessages(c)">
                                    <avatar :src="c.avatarURI" :size="30" :rounded="true" :username="c.userName" style="margin: 10px;"></avatar>
                                    <div class="chat-info">
                                        <div class="chat-from">
                                            <div class="name">{{c.userName}}</div>
                                        </div>
                                        <div class="chat-context">
                                            <div class="text">{{ c.content}}</div>
                                            <div class="status delivered">
                                                <em class="icon ni ni-check-circle-fill"></em>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li><!-- .chat-item -->
                        </ul><!-- .chat-list -->
                    </div><!-- .nk-chat-list -->
                </div></div></div></div><div class="simplebar-placeholder" style="width: auto; height: 656px;"></div></div><div class="simplebar-track simplebar-horizontal" style="visibility: hidden;"><div class="simplebar-scrollbar" style="width: 0px; display: none;"></div></div><div class="simplebar-track simplebar-vertical" style="visibility: visible;"><div class="simplebar-scrollbar" style="height: 166px; transform: translate3d(0px, 0px, 0px); display: block;"></div></div></div>
            </div><!-- .nk-chat-aside -->
            <div class="nk-chat-body">
                <div class="nk-chat-head">
                    <ul class="nk-chat-head-info">
                        <li class="nk-chat-body-close">
                            <a href="#" class="btn btn-icon btn-trigger nk-chat-hide ml-n1"><em class="icon ni ni-arrow-left"></em></a>
                        </li>
                        <li class="nk-chat-head-user">
                            <div class="user-card">
                                <avatar :src="selectedUser.userAvatarUri" :size="30" :rounded="true" :username="selectedUser.userName"></avatar>
                                <div class="user-info">
                                    <div class="lead-text">{{ selectedUser.userName }} </div>
                                    <div class="sub-text"><span class="d-none d-sm-inline mr-1">Active </span> 35m ago</div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div><!-- .nk-chat-head -->
                <div class="nk-chat-panel" data-simplebar="init">
                    <div class="simplebar-wrapper" style="margin: -20px -28px;">
                        <div class="simplebar-height-auto-observer-wrapper">
                            <div class="simplebar-height-auto-observer">
                        </div>
                    </div>
                    <div class="simplebar-mask">
                        <div class="simplebar-offset" style="right: 0px; bottom: 0px;">
                            <div class="simplebar-content-wrapper" style="height: 100%; overflow: hidden scroll;" id="messageContent">
                                <div class="simplebar-content" style="padding: 20px 28px;" v-if="messages.length">
                                    <div class="chat" v-for="message in messages" v-bind:key="message.id" :class="{ 'is-me': message.fromMe === true, 'is-you': message.fromMe != true}" >
                                        <!-- <div class="chat-avatar" >
                                            <avatar :src="message.userAvatarURI" :size="30" :rounded="true" :username="message.userName" style="margin: 0px"></avatar>
                                        </div> -->
                                        <div class="chat-content">
                                            <div class="chat-bubbles">
                                                <div class="chat-bubble">
                                                    <div class="chat-msg"> {{ message.content }} </div>
                                                    <ul class="chat-msg-more">
                                                        <li class="d-none d-sm-block"><a href="#" class="btn btn-icon btn-sm btn-trigger"><em class="icon ni ni-reply-fill"></em></a></li>
                                                        <li>
                                                            <div class="dropdown">
                                                                <a href="#" class="btn btn-icon btn-sm btn-trigger dropdown-toggle" data-toggle="dropdown"><em class="icon ni ni-more-h"></em></a>
                                                                <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                                                    <ul class="link-list-opt no-bdr">
                                                                        <li class="d-sm-none"><a href="#"><em class="icon ni ni-reply-fill"></em> Reply</a></li>
                                                                        <li><a href="#"><em class="icon ni ni-pen-alt-fill"></em> Edit</a></li>
                                                                        <li><a href="#"><em class="icon ni ni-trash-fill"></em> Remove</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul class="chat-meta">
                                                <li v-if="message.fromMe === false">{{ message.userName }}</li>
                                                <li v-if="message.fromMe === true">{{ message.createdByUser }}</li>
                                                <li>{{ $moment(message.createdDate).fromNow() }}</li>
                                            </ul>
                                        </div>
                                    </div><!-- .chat -->
                                </div>
                                <div class="chat-sap" v-else>
                                    <div class="chat-sap-meta">
                                        <span>No messages</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <div class="simplebar-placeholder" style="width: auto; height: 769px;"></div></div><div class="simplebar-track simplebar-horizontal" style="visibility: hidden;"><div class="simplebar-scrollbar" style="width: 0px; display: none;"></div></div><div class="simplebar-track simplebar-vertical" style="visibility: visible;"><div class="simplebar-scrollbar" style="height: 89px; transform: translate3d(0px, 0px, 0px); display: block;"></div></div></div><!-- .nk-chat-panel -->
                <div class="nk-chat-editor">
                    <div class="nk-chat-editor-form">
                        <div class="form-control-wrap">
                            <textarea v-on:keyup.enter="addMessage()" class="form-control form-control-simple no-resize" rows="1" id="default-textarea" placeholder="Type your message..." v-model="message.content" :class="{'is-error' : $v.message.content.$invalid }"></textarea>
                        </div>
                    </div>
                    <ul class="nk-chat-editor-tools g-2">
                        <!-- <li>
                            <a href="#" class="btn btn-sm btn-icon btn-trigger text-primary"><em class="icon ni ni-happyf-fill"></em></a>
                        </li> -->
                        <li>
                            <button class="btn btn-round btn-primary btn-icon" v-on:click="addMessage()"><em class="icon ni ni-send-alt"></em></button>
                        </li>
                    </ul>
                </div><!-- .nk-chat-editor -->
            </div><!-- .nk-chat-body -->
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
    validations: {
        message: {
            content: {
                required
            }
        }
    },
    data() {
        return {
            selectedUser: {},
            messages: [],
            conversations: [],
            users: [],
            message: {
                content: '',
                userId: '',
                createdDate: new Date()
            },
            filterOption: ''
        }
    },
    created() {
        this.getView()
        this.getConversations()
        this.getLists()
    },
    methods: {
        getView (){ this.$http.get('/ViewAccess/mymessages')
        .then(() => { 
        })
        .catch(() => { 
        })},
        getUserMessages(c) {
            this.selectedUser = c
            this.$http.get('/messaging/Get_UserMessages/' + c.userId)
            .then((response) => {
                this.messages = response.data
                this.$nextTick(function() {
                    let messageContent = this.$el.querySelector('#messageContent')
                    messageContent.scrollTop = messageContent.scrollHeight
                })
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getConversations() {
            this.$http.get('/messaging/Get_Conversations/')
            .then((response) => {
                this.conversations = response.data
                if (this.conversations.length){
                    this.getUserMessages(this.conversations[0])
                }
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getLists() {
            this.$http.get('/lists/Get_Users')
            .then((response) => {
                this.users = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        addMessage() {
            if (this.$v.message.content.$invalid) {
                this.$message.error('Please enter a message')
            } else {
                this.message.userId = this.selectedUser.userId
                this.$http.post('/messaging/Add_Message', this.message)
                .then(() => {
                    this.$message.success('Message Sent')
                    this.getUserMessages(this.selectedUser)
                    this.resetMessage()
                    this.updateConversations()
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
            }
        },
        resetMessage() {
            this.message = {
                content: '',
                userId: '',
                createdDate: new Date()
            }
        },
        getBlankUserMessages(user) {
            let userMessages = { userId: user.id, userName: user.firstName + ' ' + user.lastName }
            this.getUserMessages(userMessages)
            this.selectedUser = userMessages
            this.updateConversations()
        },
        updateConversations() {
            this.$http.get('/messaging/Get_Conversations/')
            .then((response) => {
                this.conversations = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        }
    }
}
</script>

<style>

</style>